var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field",class:{
  'field-has-error': _vm.error,
  'opacity-25': _vm.disabled,
}},[(_vm.label)?[_c('div',{staticClass:"flex justify-between mb-1"},[_c('label',{staticClass:"block text-sm font-medium text-gray-700 truncate",attrs:{"for":_vm.cInputId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.showValidationLabel && !_vm.required)?[_c('span',{staticClass:"text-sm text-gray-500"},[_vm._v(_vm._s(_vm.$t('optional')))])]:_vm._e()],2)]:_vm._e(),_c('div',{staticClass:"relative"},[(_vm.icon)?[_c('div',{staticClass:"absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"},[_c('app-svg',{staticClass:"h-4 w-4 text-gray-400",attrs:{"svg":_vm.icon}})],1)]:_vm._e(),_vm._t("default",[_c('input',{staticClass:"sm:text-sm appearance-none block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 focus:outline-none focus:ring-app-orange focus:border-app-orange",class:{
          'pl-10': _vm.icon,
          'border-red-500': _vm.error,
        },attrs:{"id":_vm.cInputId,"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":_vm.input}})]),(_vm.error)?[_c('div',{staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"},[_c('app-svg',{staticClass:"h-5 w-5 text-red-500",attrs:{"svg":"exclamation-circle"}})],1)]:_vm._e(),(_vm.canCopy)?[_c('a',{staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center",class:{
        'hidden': _vm.error,
      },attrs:{"title":_vm.$t('copy')},on:{"click":_vm.copy}},[_c('app-svg',{staticClass:"h-5 w-5 text-gray-400 cursor-pointer",attrs:{"svg":!_vm.isCopied ? 'clipboard-copy' : 'clipboard-check'}})],1)]:_vm._e()],2),(_vm.help)?_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(_vm._s(_vm.help))]):_vm._e(),(_vm.error)?[_c('p',{staticClass:"mt-2 text-sm text-red-600"},[_vm._v(_vm._s(_vm.error))])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }