import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    cInputId() {
      return this.formId ? `${this.formId}-${this.inputId}` : this.inputId;
    },
    ...mapGetters({
      getFieldErrors: 'validation/getFieldErrors',
    }),
    error() {
      return this.formId && this.formErrorId ? this.getFieldErrors(this.formId, this.formErrorId)[0] : null;
    },
  },
  methods: {
    ...mapActions({
      clearFieldValidationErrors: 'validation/clearFieldValidationErrors',
    }),
    clearErrors() {
      if (this.formId && this.formErrorId) {
        this.clearFieldValidationErrors({
          namespace: this.formId,
          field: this.formErrorId,
        });
      }
    },
    input(event) {
      // this.clearErrors();
      this.$emit('input', event.target.value);
    },
    change(event) {
      // https://stackoverflow.com/a/49684109
      this.$emit('input', [...event.target.selectedOptions].map((option) => option.value));
    },
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    formErrorId: {
      type: String,
    },
    formId: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
    },
    label: {
      type: [
        Boolean,
        String,
      ],
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [
        String,
        Array,
        Boolean,
        Number,
      ],
      default: '',
    },
  },
};
