<template>
  <form novalidate autocomplete="off" @submit.prevent="$emit('submit')" @input="input">
    <slot />
  </form>
</template>

<script>
export default {
  methods: {
    input($event) {
      this.$emit('input', $event.target.name);
    },
  },
};
</script>
