import FormCheck from '@/components/FormCheck.vue';
import FormOpen from '@/components/FormOpen.vue';
import FormFiles from '@/components/FormFiles.vue';
import FormSelect from '@/components/FormSelect.vue';
import FormText from '@/components/FormText.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import Multiselect from 'vue-multiselect';
import store from '@/store';
import ValidationErrors from '@/components/ValidationErrors.vue';

export default {
  components: {
    FormCheck,
    FormFiles,
    FormOpen,
    FormSelect,
    FormText,
    FormTextarea,
    Multiselect,
    ValidationErrors,
  },
  methods: {
    toSelectOptions(data, label = 'name', value = 'id', labelTransKey = null) {
      const options = [];
      data.forEach((item) => {
        options.push({
          value: item[value],
          label: labelTransKey === null ? item[label] : this.$t(labelTransKey(item[value], item[label])),
        });
      });

      return options;
    },
    clearFormErrors(formId) {
      store.dispatch('validation/clearValidationErrors', {
        namespace: formId,
      });
    },
  },
};
