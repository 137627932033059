<template>
  <form-text :input-id="cInputId">
    <div class="relative flex items-start">
      <div class="flex items-center h-5">
        <input class="focus:ring-app-orange h-4 w-4 text-app-orange border-gray-300"
          :id="cInputId"
          :type="type"
          :value="trueValue"
          :checked="isChecked"
          @change="change"
        >
      </div>
      <div class="ml-3 text-sm">
        <label class="font-medium text-gray-700" :for="cInputId">{{ label }}</label>
        <p class="text-gray-400" v-if="help">{{ help }}</p>
      </div>
    </div>
  </form-text>
</template>

<script>
import FormEl from '@/mixins/FormEl';
import FormText from '@/components/FormText.vue';

export default {
  components: {
    FormText,
  },
  computed: {
    isChecked() {
      if (this.value instanceof Array) {
        return this.value.includes(this.trueValue);
      }

      return this.value === this.trueValue;
    },
  },
  mixins: [
    FormEl,
  ],
  methods: {
    change($event) {
      const isChecked = $event.target.checked;

      if (this.value instanceof Array) {
        const newArray = [...this.value];

        if (isChecked) {
          newArray.push(this.trueValue);
        } else {
          newArray.splice(newArray.indexOf(this.trueValue), 1);
        }
        this.$emit('input', newArray);
      } else {
        this.$emit('input', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
  props: {
    falseValue: {
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    trueValue: {
      type: [
        String,
        Number,
      ],
      required: true,
    },
    type: {
      default: 'checkbox',
      validator(value) {
        return ['checkbox', 'radio'].indexOf(value) !== -1;
      },
    },
  },
};
</script>
