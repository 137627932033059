<template>
  <form-text :label="label" :form-id="formId" :form-error-id="formErrorId" :input-id="inputId" :required="required">
    <textarea class="block w-full focus:ring-app-orange focus:border-app-orange sm:text-sm border-gray-300"
      :id="cInputId"
      :rows="rows"
      :value="value"
      @input="input"
      @keydown.enter.shift.exact.prevent="$emit('submit')"
      :class="{
        'border-red-500': error,
      }"
      :disabled="disabled"
    ></textarea>
  </form-text>
</template>

<script>
import FormText from '@/components/FormText.vue';
import FormEl from '@/mixins/FormEl';

export default {
  components: {
    FormText,
  },
  inheritAttrs: false,
  mixins: [
    FormEl,
  ],
  props: {
    rows: {
      default: 2,
      type: Number,
    },
    value: {
      default: '',
      type: String,
    },
  },
};
</script>
