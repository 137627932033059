<template>
  <div class="field" :class="{
    'field-has-error': error,
    'opacity-25': disabled,
  }">
    <template v-if="label">
      <div class="flex justify-between mb-1">
        <label
          class="block text-sm font-medium text-gray-700 truncate"
          :for="cInputId"
        >
          {{ label }}
        </label>

        <template v-if="showValidationLabel && !required">
          <span class="text-sm text-gray-500">{{ $t('optional') }}</span>
        </template>
      </div>
    </template>

    <div class="relative">
      <template v-if="icon">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <app-svg class="h-4 w-4 text-gray-400" :svg="icon"/>
        </div>
      </template>

      <slot>
        <input
          class="sm:text-sm appearance-none block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 focus:outline-none focus:ring-app-orange focus:border-app-orange"
          :id="cInputId"
          :type="type"
          :class="{
            'pl-10': icon,
            'border-red-500': error,
          }"
          :value="value"
          @input="input"
          :placeholder="placeholder"
          :disabled="disabled"
          :autocomplete="autocomplete"
        >
      </slot>

      <template v-if="error">
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <app-svg class="h-5 w-5 text-red-500" svg="exclamation-circle"/>
        </div>
      </template>

      <template v-if="canCopy">
        <a class="absolute inset-y-0 right-0 pr-3 flex items-center" :class="{
          'hidden': error,
        }"  @click="copy" :title="$t('copy')">
          <app-svg class="h-5 w-5 text-gray-400 cursor-pointer" :svg="!isCopied ? 'clipboard-copy' : 'clipboard-check'"/>
        </a>
      </template>
    </div>

    <p class="mt-2 text-sm text-gray-500" v-if="help">{{ help }}</p>

    <template v-if="error">
      <p class="mt-2 text-sm text-red-600">{{ error }}</p>
    </template>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';
import FormEl from '@/mixins/FormEl';

export default {
  components: {
    AppSvg,
    // FormLabel,
  },
  data() {
    return {
      isCopied: false,
    };
  },
  inheritAttrs: false,
  methods: {
    copy() {
      navigator.clipboard.writeText(this.value);

      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    },
  },
  mixins: [
    FormEl,
  ],
  props: {
    autocomplete: {
      default: 'off',
      type: String,
    },
    canCopy: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    placeholder: {
      default: '',
      type: String,
    },
    showValidationLabel: {
      type: Boolean,
      default: true,
    },
    type: {
      default: 'text',
      type: String,
    },
  },
};
</script>
