<template>
  <transition>
    <div class="bg-red-50 p-4" v-if="getErrors.length && visible">
      <div class="flex">
        <div class="flex-shrink-0">
          <app-svg class="h-5 w-5 text-red-400" svg="x-circle"/>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            {{ $t('validation_errors_text', { count: getErrors.length }) }}
          </h3>
          <div class="mt-2 text-sm text-red-700">
            <ul class="list-disc pl-5">
              <li v-for="(error, index) in getErrors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';
import AppSvg from '@/components/AppSvg.vue';

export default {
  components: {
    AppSvg,
  },
  computed: {
    ...mapGetters({
      validationGetErrors: 'validation/getErrors',
    }),
    getErrors() {
      const globalFormErrors = (this.formId ? this.validationGetErrors(this.formId).flatten() : []);

      return this.errors.length === 0 ? globalFormErrors : this.errors;
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  props: {
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    formId: {
      type: String,
      default: null,
    },
  },
};
</script>
