<template>
  <file-pond
    :allowRevert="false"
    :allow-multiple="multiple"
    :name="name"
    :ref="name"
    :credits="[]"
    :label-idle="labelIdle"
    :server="server"
    :files="files"
    @processfiles="$emit('processfiles')"
  />
</template>

<script>
import vueFilePond from 'vue-filepond';

const FilePond = vueFilePond();

export default {
  components: {
    FilePond,
  },
  props: {
    credits: {
      default: false,
      type: Boolean,
    },
    files: {
      default: null,
      required: true,
      type: Array,
    },
    labelIdle: {
      default() {
        return this.$t('filepond_label_idle');
      },
      type: String,
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    name: {
      required: true,
      type: String,
    },
    server: {
      required: true,
      type: Object,
    },
  },
};
</script>
