<template>
  <form-text
    :form-error-id="formErrorId"
    :form-id="formId"
    :help="help"
    :input-id="inputId"
    :label="label"
    :show-validation-label="showValidationLabel"
    :required="required"
  >
    <template v-if="useMultiselect">
      <multiselect
        :_deselectLabel="deselectLabel"
        :allow-empty="!required"
        :close-on-select="closeOnSelect"
        :custom-label="customLabel"
        :limit="limit"
        :loading="loading"
        :id="cInputId"
        :internal-search="internalSearch"
        :multiple="multiple"
        :options="optionIds"
        :placeholder="placeholder"
        :value="value"
        @input="multiselectInput"
        @search-change="searchChange"
      />
    </template>
    <template v-else>
      <select
        class="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-app-orange focus:border-app-orange sm:text-sm"
        :id="cInputId"
        :multiple="multiple"
        @change="change"
      >
        <option v-if="placeholder" value="">{{ placeholder }}</option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >{{ option.label ? option.label : option.value }}</option>
      </select>
    </template>
  </form-text>
</template>

<script>
import _ from 'lodash';
import FormEl from '@/mixins/FormEl';
import FormText from '@/components/FormText.vue';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    FormText,
    Multiselect,
  },
  computed: {
    closeOnSelect() {
      return !this.multiple;
    },
    deselectLabel() {
      return this.required ? '' : null;
    },
    optionIds() {
      return _.map(this.options, 'value');
    },
  },
  inheritAttrs: false,
  methods: {
    customLabel(value) {
      // eslint-disable-next-line eqeqeq
      return _.result(_.find(this.options, (option) => option.value == value), 'label');
    },
    multiselectInput(value) { // https://vue-multiselect.js.org/#sub-events
      this.$emit('input', value);
    },
    searchChange(searchQuery) {
      this.$emit('search-change', searchQuery);
    },
  },
  mixins: [
    FormEl,
  ],
  props: {
    internalSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 6,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
    },
    options: {
      required: true,
      type: Array,
    },
    placeholder: {
      type: [
        String,
        Boolean,
      ],
      default() {
        return this.$t('select_placeholder');
      },
    },
    showValidationLabel: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    useMultiselect: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
